import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../../../../../components/ContentContainer/ContentContainer";
import { TopBarContext } from "../../../../../../context/TopBarContext";
import CloseButton from "../../../../../../UI/Buttons/Actions/CloseButton";
import { ReactComponent as CopyIcon } from "../../../../../../assets/images/icons/common/copyIcon.svg";
import Loading from "../../../../../../components/Loading";
import { ReactComponent as EditIcon } from "../../../../../../assets/images/icons/common/edit-icon-white.svg";
import { ReactComponent as SaveIcon } from "../../../../../../assets/images/icons/saveIconWhite.svg";
import { AuthContext } from "../../../../../../context/AuthContext";
import { createProduct, editProduct, getClientList, getPlacements, getProductByID, getTemplate, getTemplatesList } from "../../../../services/fetchers";
import SideStickWidgetConfigsForm from "./SideStickWidgetConfigsForm";

export type ProductConfigs = {
    [key: string]: any;
};

export const boxStyle = {
    padding: "3rem",
    background: "#fff",
    maxWidth: "736px",
    gap: "1rem",
    display: "flex",
    height: "100%",
};

export type FormType = {
    [key: string]: Record<string, any> | string | unknown | any;
};

const SideStickWidgetDetails = () => {
    const { setNotification } = useContext(AuthContext);
    const [configs, setConfigs] = useState<ProductConfigs>({
        template_desktop: "",
    });
    const [mobileTemplates, setMobileTemplates] = useState<string[]>([]);
    const [desktopTemplates, setDesktopTemplates] = useState<string[]>([]);
    const [clientList, setClientList] = useState<string[]>([]);
    const [placementList, setPlacementList] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [dynamicForm, setDynamicForm] = useState<FormType>({
        main: {},
        additional: {},
        additional_mobile: {},
    });
    const { handleChangePath, handleSetActions } = useContext(TopBarContext);
    const [firstLoad, setFirstLoad] = useState(true);
    const { id } = useParams();
    const mode = id ? "edit" : "create";
    const { t } = useTranslation();
    const productName = "side_sticky";
    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
    } = useForm();

    const actionBtnStyle = {
        padding: "9px 24px",
        background: "var(--button-background-color)",
        display: "flex",
        gap: ".5rem",
        alignItems: "center",
        border: "none",
        cursor: "pointer",
    };
    useEffect(() => {
        const ActionButton = (
            <button style={{ ...actionBtnStyle, textTransform: "uppercase" }} type="submit" onClick={handleSubmit(onSubmit)}>
                <Typography sx={{ fontWeight: "500", color: "#fff" }}>{t(mode === "edit" ? "button.edit" : "button.save")}</Typography>
                {mode === "edit" ? <EditIcon style={{ color: "#fff" }} /> : <SaveIcon style={{ color: "#fff" }} />}
            </button>
        );
        handleChangePath(["Products", "Sticky Widget"]);
        handleSetActions([ActionButton, <CloseButton path="/products/side_stick" />]);
    }, [dynamicForm.main, dynamicForm.additional, dynamicForm.additional_mobile, configs]);

    useEffect(() => {
        if (id) {
            (async () => {
                setLoading(true);
                const res = await getProductByID(productName, id);
                setDynamicForm({
                    ...dynamicForm,
                    ...res,
                });
                setConfigs({
                    template_desktop: res.template_desktop,
                    client: res.client,
                    placement_name: res.placement_name,
                    placement_id: res.placement_id,
                });
                setLoading(false);
            })();
        }
    }, [id]);

    const getTemplates = async () => {
        try {
            setLoading(true);
            const res = await Promise.all([await getTemplatesList(productName, "mobile"), await getTemplatesList(productName, "desktop"), await getClientList()]);
            setMobileTemplates(res[0]);
            setDesktopTemplates(res[1]);
            setClientList(res[2]);
            setTimeout(() => setFirstLoad(false), 100);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (configs.client) {
            (async () => setPlacementList(await getPlacements(configs.client)))();
        }
    }, [configs.client]);

    useEffect(() => {
        if (configs.template_desktop && !firstLoad) {
            (async () => {
                const data = await getTemplate(configs.template_desktop);
                if (Object.keys(dynamicForm.main).length > 0) {
                    setDynamicForm({
                        ...dynamicForm,
                        ...data,
                    });
                } else {
                    setDynamicForm({
                        ...dynamicForm,
                        ...data,
                    });
                }
            })();
        }
    }, [configs.template_desktop]);

    useEffect(() => {
        getTemplates();
    }, []);

    const handleSaveConfigs = async (configs: Record<string, any>) => {
        try {
            setLoading(true);
            const res = await createProduct(productName, configs);
            setNotification({
                type: "success",
                message: res.message,
                open: true,
            });
        } catch (err: any) {
            setNotification({
                type: "error",
                message: err.message,
                open: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleEditConfigs = async (configs: Record<string, any>) => {
        try {
            setLoading(true);
            const res = await editProduct(productName, id, configs);
            setNotification({
                type: "success",
                message: res.message,
                open: true,
            });
        } catch (err: any) {
            setNotification({
                type: "error",
                message: err.message,
                open: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (data: any) => {
        let res = { ...data };
        const dataKeys = Object.keys(data);
        Object.keys(dynamicForm).forEach((key) => {
            Object.keys(dynamicForm[key]).forEach((field) => {
                const valueSettings = dynamicForm[key][field];
                let adf_params;
                if (field === "overlay_adf_params" || field === "overlay_adf_params_2") {
                    adf_params = valueSettings.fields.map((item: any) => {
                        const itemKeys = dataKeys.filter((key) => key.endsWith(item.fieldId));
                        const res = {} as Record<string, any>;
                        itemKeys.forEach((keyWithId) => {
                            const key = keyWithId.split("-")[0];
                            res[key] = data[keyWithId];
                        });
                        res.fieldId = item.fieldId;
                        return res;
                    });
                    res[field] = adf_params;
                } else if (valueSettings.typeSend === "array") {
                    if (res[field]) {
                        res[field] = JSON.parse(`[${res[field]}]`);
                    } else {
                        res[field] = [];
                    }
                } else if (valueSettings.typeSend === "object") {
                    if (res[field]) {
                        if (res[field] === "p1: '',p2: ''") {
                            res[field] = {};
                        } else {
                            res[field] = JSON.parse(`${res[field]}`);
                        }
                    } else {
                        res[field] = {};
                    }
                }
            });
        });
        res = Object.fromEntries(Object.entries(res).filter(([key, value]) => !(key.includes("p1") || key.includes("p2"))));

        res = {
            ...res,
            ...configs,
        };
        if (mode === "edit") {
            await handleEditConfigs(res);
        } else {
            await handleSaveConfigs(res);
        }
    };

    const overlay_block_id = watch("overlay_block_id");
    const isWidgetCodeReady = id && overlay_block_id && configs.placement_id;

    const getWidgetCode = useMemo(() => {
        if (!isWidgetCodeReady) {
            return <Typography>There will be code after creating PVW block, which you should give for clients.</Typography>;
        }
        return `
            Head
            <script async src="https://cdn.tds.bid/bid.js" type="text/javascript" />
            Body
            <div id="side-stick">
                <script>
                    if (window.defineRecWidget){
                        window.defineRecWidget({
                            containerId: "side-stick",
                            plId: "${configs.placement_id}",
                            prId: "${configs.placement_id}-${id}",
                            product: "side_sticky",
                        })
                    }else{
                        window.recWait = window.recWait || [];
                        window.recWait.push({
                            containerId: "side-stick",
                            plId: "${configs.placement_id}",
                            prId: "${configs.placement_id}-${id}",
                            product: "side_sticky",
                        })
                    }
                </script>
            </div>
        `;
    }, [id, configs.placement_id, overlay_block_id]);

    const addToClipBoard = () => {
        if (navigator && isWidgetCodeReady) {
            navigator.clipboard.writeText(getWidgetCode as string);
        }
    };
    return (
        <ContentContainer>
            {!loading ? (
                <Box sx={{ display: "flex", gap: ".75rem" }}>
                    <SideStickWidgetConfigsForm
                        mode={mode}
                        errors={errors}
                        control={control}
                        configs={configs}
                        setConfigs={setConfigs}
                        desktopTemplates={desktopTemplates}
                        clientList={clientList}
                        placementList={placementList}
                        dynamicForm={dynamicForm}
                        setDynamicForm={setDynamicForm}
                    />
                    <Box sx={{ ...boxStyle, width: "40%", flexWrap: "wrap" }}>
                        <Box
                            sx={{
                                backgroundColor: "var(--main-purple-hover-color)",
                                p: "1rem",
                                display: "flex",
                                justifyContent: "space-between",
                                maxWidth: "100%",
                            }}
                        >
                            <code style={{ width: "95%" }}>{getWidgetCode}</code>
                            <Tooltip title="Copy to clipboard">
                                <IconButton sx={{ p: "0", height: "fit-content" }} onClick={addToClipBoard}>
                                    <CopyIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box sx={{ height: "100%", display: "flex", justifyContent: "center" }}>
                    <Loading />
                </Box>
            )}
        </ContentContainer>
    );
};

export default SideStickWidgetDetails;