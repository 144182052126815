import {Box, IconButton, Tooltip, Typography} from "@mui/material";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../../../../context/AuthContext";
import {TopBarContext} from "../../../../../context/TopBarContext";
import {ReactComponent as CopyIcon} from "../../../../../assets/images/icons/common/copyIcon.svg";
import {ReactComponent as EditIcon} from "../../../../../assets/images/icons/common/edit-icon-white.svg";
import {ReactComponent as SaveIcon} from "../../../../../assets/images/icons/saveIconWhite.svg";
import CloseButton from "../../../../../UI/Buttons/Actions/CloseButton";
import {
    createProduct,
    editProduct,
    getClientList,
    getPlacements,
    getProductByID,
    getTemplate
} from "../../../services/fetchers";
import ContentContainer from "../../../../../components/ContentContainer/ContentContainer";
import Loading from "../../../../../components/Loading";
import PerfectAdUnitConfigsForm from "./PerfectAdUnitConfigsForm";

export type ProductConfigs = {
    [key: string]: any;
};

export const boxStyle = {
    padding: "3rem",
    background: "#fff",
    maxWidth: "736px",
    gap: "1rem",
    display: "flex",
    height: "100%",
};

const actionBtnStyle = {
    padding: "9px 24px",
    background: "var(--button-background-color)",
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
};

export type FormType = {
    [key: string]: Record<string, any> | string | unknown | any;
};

const PerfectAdUnitWidgetDetails = () => {
    const navigate = useNavigate();
    const { setNotification } = useContext(AuthContext);
    const [configs, setConfigs] = useState<ProductConfigs>({});
    const [clientList, setClientList] = useState<string[]>([]);
    const [placementList, setPlacementList] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [dynamicForm, setDynamicForm] = useState<FormType>({
        main: {},
    });
    const { handleChangePath, handleSetActions } = useContext(TopBarContext);
    const [firstLoad, setFirstLoad] = useState(true);
    const { id } = useParams();
    const mode = id ? "edit" : "create";
    const { t } = useTranslation();
    const productName = "PAU";
    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
    } = useForm();

    useEffect(() => {
        const ActionButton = (
            <button style={{ ...actionBtnStyle, textTransform: "uppercase" }} type="submit" onClick={handleSubmit(onSubmit)}>
                <Typography sx={{ fontWeight: "500", color: "#fff" }}>{t(mode === "edit" ? "button.edit" : "button.save")}</Typography>
                {mode === "edit" ? <EditIcon style={{ color: "#fff" }} /> : <SaveIcon style={{ color: "#fff" }} />}
            </button>
        );
        handleChangePath(["Products", "PAU"]);
        handleSetActions([ActionButton, <CloseButton path="/products/pau" />]);
    }, [dynamicForm.main, dynamicForm.additional, dynamicForm.additional_mobile, configs]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTemplates();
            if (id) {
                const res = await getProductByID(productName, id);
                setDynamicForm({
                    ...dynamicForm,
                    ...res,
                    main: {
                        ...res.main
                    },
                });
                setConfigs({
                    client: res.client,
                    placement_name: res.placement_name,
                    placement_id: res.placement_id,
                });
            } else {
                const data = await getTemplate("pau");
                if (Object.keys(dynamicForm.main).length > 0) {
                    setDynamicForm({
                        ...dynamicForm,
                        ...data,
                        main: {
                            ...data.main,
                        },
                    });
                } else {
                    setDynamicForm({
                        ...dynamicForm,
                        ...data,
                    });
                }
            }
            setLoading(false);
        })()
    }, [id]);

    const getTemplates = async () => {
        try {
            const res = await getClientList();
            setClientList(res);
            setTimeout(() => setFirstLoad(false), 100);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (configs.client) {
            (async () => setPlacementList(await getPlacements(configs.client)))();
        }
    }, [configs.client]);

    const handleSaveConfigs = async (configs: Record<string, any>) => {
        try {
            setLoading(true);
            const res = await createProduct(productName, configs);
            setNotification({
                type: "success",
                message: res.message,
                open: true,
            });
        } catch (err: any) {
            setNotification({
                type: "error",
                message: err.message,
                open: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleEditConfigs = async (configs: Record<string, any>) => {
        try {
            setLoading(true);
            const res = await editProduct(productName, id, configs);
            setNotification({
                type: "success",
                message: res.message,
                open: true,
            });
        } catch (err: any) {
            setNotification({
                type: "error",
                message: err.message,
                open: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (data: any) => {
        let res = { ...data };
        Object.keys(dynamicForm).forEach((key) => {
            Object.keys(dynamicForm[key]).forEach((field) => {
                const valueSettings = dynamicForm[key][field];
                if (valueSettings.typeSend === "array") {
                    if (res[field]) {
                        res[field] = res[field].toString().split(",").map((item: string) => item.trim());
                    } else {
                        res[field] = [];
                    }
                }
            });
        });
        res = {
            ...res,
            ...configs,
        };
        if (mode === "edit") {
            await handleEditConfigs(res);
        } else {
            await handleSaveConfigs(res);
        }
        navigate(-1);
    };

    const desktop_block_ids = watch("desktop_block_ids");
    const mobile_block_ids = watch("mobile_block_ids");
    const isWidgetCodeReady = id && (desktop_block_ids || mobile_block_ids) && configs.placement_id;

    const getWidgetCode = useMemo(() => {
        if (!isWidgetCodeReady) {
            return <Typography>There will be code after creating PVW block, which you should give for clients.</Typography>;
        }
        return `
            Head
            <script src="https://cdn.tds.bid/bid.js" type="text/javascript"></script>
            Body
            <div id="pau-id">
                <script>
                    if (window.defineRecWidget){
                        window.defineRecWidget({
                            containerId: "pau-id",
                            plId: "${configs.placement_id}",
                            prId: "${configs.placement_id}-${id}",
                            product: "pau",
                        })
                    }else{
                        window.recWait = window.recWait || [];
                        window.recWait.push({
                            containerId: "pau-id",
                            plId: "${configs.placement_id}",
                            prId: "${configs.placement_id}-${id}",
                            product: "pau",
                        })
                    }
                </script>
            </div>`;
    }, [id, configs.placement_id, isWidgetCodeReady]);

    const addToClipBoard = () => {
        if (navigator && isWidgetCodeReady) {
            navigator.clipboard.writeText(getWidgetCode as string);
        }
    };

    return (
        <ContentContainer>
            {!loading ? (
                <Box sx={{ display: "flex", gap: ".75rem" }}>
                    <PerfectAdUnitConfigsForm
                        mode={mode}
                        errors={errors}
                        control={control}
                        configs={configs}
                        setConfigs={setConfigs}
                        clientList={clientList}
                        placementList={placementList}
                        dynamicForm={dynamicForm}
                        setDynamicForm={setDynamicForm}
                    />
                    <Box sx={{ ...boxStyle, width: "40%", flexWrap: "wrap" }}>
                        <Box
                            sx={{
                                backgroundColor: "var(--main-purple-hover-color)",
                                p: "1rem",
                                display: "flex",
                                justifyContent: "space-between",
                                maxWidth: "100%",
                            }}
                        >
                            <code style={{ width: "95%" }}>{getWidgetCode}</code>
                            <Tooltip title="Copy to clipboard">
                                <IconButton sx={{ p: "0", height: "fit-content" }} onClick={addToClipBoard}>
                                    <CopyIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box sx={{ height: "100%", display: "flex", justifyContent: "center" }}>
                    <Loading />
                </Box>
            )}
        </ContentContainer>
    );
};

export default PerfectAdUnitWidgetDetails;
