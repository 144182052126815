import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import getDashboard from "./services/fetchrers";
import { TableDataType } from "../../components/TableConstructor/types";
import { Dictionary } from "../ClientsPage";
import CustomButton from "../../UI/Buttons/CustomButton";
import { ReactComponent as TelegramIcon } from "../../assets/images/icons/common/telegramIconWhite.svg";
import CardItems from "./components/CardItems";
import { CardType } from "./components/Card";
import CheckCard from "./components/CheckCard";
import { TopBarContext } from "../../context/TopBarContext";
import { initialTableData } from "../../components/TableConstructor/constants";
import TableConstructor from "../../components/TableConstructor";
import { initialCards } from "./services/constants";

const yesterday = dayjs().add(-1, "day");

const DashboardPage = () => {
    const { handleChangePath, handleSetActions, handleChangeTabs } = useContext(TopBarContext);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState<Dictionary<any>>({
        periods: {
            date_from: yesterday.startOf("month"),
            date_to: yesterday,
            currency: "",
        },
    });
    const [cards, setCards] = useState<CardType[]>(initialCards);
    const [bill, setBill] = useState({});
    const [tableData, setTableData] = useState<TableDataType>(initialTableData);

    useEffect(() => {
        handleChangePath(["dashboard"]);
        handleSetActions([]);
        handleChangeTabs([]);
    }, []);

    const loadClients = async (signal: any = null) => {
        setLoading(true);
        const data = {
            ...request,
            filters: request.filters || {},
            periods: {
                ...request.periods,
                date_from: dayjs(request.periods.date_from).format("YYYY-MM-DD"),
                date_to: dayjs(request.periods.date_to).format("YYYY-MM-DD"),
            },
        };
        try {
            const res = await getDashboard(data, signal);
            setTableData({
                ...res,
                table_actions: {
                    ...res.table_actions,
                    add: true,
                },
            });
            setCards(res.cards);
            setBill(res.bill);
            setLoading(false);
        } catch (e) {
            setError("Error");
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        await loadClients();
    };
    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            await loadClients(controller.signal);
        })();
        return () => controller.abort();
    }, [request.sort]);

    return (
        <ContentContainer>
            <CardItems cards={cards} loading={loading} />
            <TableConstructor
                tableData={tableData}
                loading={loading}
                request={request}
                setRequest={setRequest}
                ActionButton={<CustomButton Icon={<TelegramIcon />} label="submit" onChange={handleSubmit} />}
            />
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    p: ".75rem",
                }}
            >
                {tableData.rows.length > 0 && Object.keys(bill).length > 0 && <CheckCard bill={bill} />}
            </Box>
        </ContentContainer>
    );
};

export default DashboardPage;
