import { useTranslation } from "react-i18next";
import { ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useContext } from "react";
import { RouteType } from "../../routes/config";
import { AuthContext } from "../../context/AuthContext";

type Props = {
    item: RouteType;
    inside?: boolean;
    toggle?: boolean;
};
const SideBarItem = ({ item, inside = false, toggle = false }: Props) => {
    const { t } = useTranslation();
    const { appState, me } = useContext(AuthContext);
    const isActive = appState.includes(item.state);
    const sideBarText = typeof item.sidebarProps?.displayText === "object" ? item.sidebarProps?.displayText[me.role] : item.sidebarProps?.displayText;

    return item.sidebarProps && item.path ? (
        <ListItemButton
            component={Link}
            to={`${item.path}`}
            sx={{
                p: toggle && inside ? "1rem 1.125rem 1rem 1.75rem" : "1rem 1.125rem",
                gap: "1.125rem",
                backgroundColor: isActive ? "var(--main-purple-hover-color)" : "#fff",
                borderRight: isActive ? "3px solid var(--main-purple-color)" : "#fff",
                maxHeight: "3.5rem",
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: "auto",
                }}
            >
                {item.sidebarProps.icon && item.sidebarProps.icon}
            </ListItemIcon>
            <ListItemText sx={{ m: 0 }} disableTypography primary={<Typography fontFamily="inherit">{t(sideBarText)}</Typography>} />
        </ListItemButton>
    ) : null;
};

export default SideBarItem;
